
@import url("https://fonts.googleapis.com/css?family=Poppins:300,700,500,600");
@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("static/fonts/Radomir Tinkov - Gilroy-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/Gilroy-Semibold.otf") format("opentype");
}
:root { 
  --brand: #2069e0;
  --brand-blue: #2069e0;
  --brand-blue-visited: #84b3ff;
  --brand-green: #20e037;
  --brand-green-visited: #6be97a;
  --brand-red: #e02020;
  --dark-green: #128320;
  --chicago: #5c6061;
  --text-2: #707c97;
  --titan-white: #f2f2f2;
  --white-text: #ffffff;
  --dark-blue: #131339;
  --nav-item-hover: #a9a9a994;
  --transparent-black: #00000085;
  --transparent-white: #e9edffd6;
  --light-gray: #a9a9a9;
  --off-white: #ebebeb;
 
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 22px;
  --font-size-ll: 30px;
  --font-size-lll: 34px;
  --font-size-xl: 38px;
  --font-size-xxl: 48px;
 
  --font-family-gilroy-bold: "Gilroy-Bold", Helvetica;
  --font-family-gilroy-semibold: "Gilroy-Semibold", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
}


.recentDocs-container {
  max-width: 780px;
  padding: 20px;
  margin: 0 auto;
}

.material-icons.pac-icon-marker.industries {
  background-image: none;
  margin-right: 5px;
  font-size: var(--font-size-m);
  color: var(--light-gray);
/*  margin-top: auto;*/
}

.material-icons {
  cursor: pointer;
}
.sub-input-section {
  margin-left: 20px;
}

.copy-link {
  font-size: var(--font-size-xs);
  padding: 2px 5px;
  background-color: var(--nav-item-hover);
  border-radius: 5px;
  box-shadow: 1px 1px 7px -1px;
  cursor: pointer;
/*  vertical-align: middle;*/
}

.copy-link svg {
  width: 16px;
  height: 16px;
  vertical-align: sub;
}

/*.copy-link span {
  margin-bottom: auto;
}
*/
.underline {
  text-decoration:  underline;
}
.bold {
  font-weight: bold;
}

.info-tag {
  background-color: var(--nav-item-hover);
  font-size: 12px;
  padding: 0 5px 1px 5px;
  margin: 0 5px;
  cursor: help;
}
.billTo-user-card {
  display: flex;
  align-items: center;
}
.billTo-user-card > img {
  padding: 2px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}
.import-contacts {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}
.import-contacts > img {
  background-color: white;
  padding: 2px 4px;
  border-radius: 10%;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.pac-logo:after {
  background-image: none !important;
}
.pac-container {
  background-color: #fff;
  position: absolute!important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.pac-item {
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 11px;
  color: #515151;
}
.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #000;
}
  
.industries-list .pac-icon pac-icon-marker {
  display: none;
}

.poppins-light-chicago-16px {
  color: var(--chicago);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 300;
}

.poppins-bold-mariner-24px {
  color: var(--brand);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.poppins-medium-slate-gray-12px {
  color: var(--text-2);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}
.logo {
  height: 40px;
}


@font-face {
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
}
body {
  margin: 0;
  font-family: Poppins;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*font-family: system-ui;*/
  /*font-family: system-ui, sans-serif;*/
  /*letter-spacing: normal;*/
  background-color: var(--white-text);
  text-align: center;
  align-items: center;
}
#top-of-page {
  height: 130px;
}

.clickable {
  cursor: pointer;
}
.strikethrough {
  text-decoration: line-through;
}

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

details {
  cursor: pointer;
}

details > summary {
  cursor: pointer;
  border: none;
  outline: none;
}

details.hide-marker > summary {
  list-style: none;
}

details.hide-marker > summary::marker,
details.hide-marker > summary::-webkit-details-marker {
  display: none;
}

.App {
  margin: auto;
  /*margin-top: 130px;*/
  /*margin-bottom: 200px;*/
  position: relative;
}
.grant-permissions {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 5px 20px;
  background-color: #b5b5b5;
  color: black;
  width: 100%;
  /*opacity: 0.9;*/
  border-radius: 5px;
  text-align: left;
  z-index: 100;
}

.dot-actions-container {
  position: absolute;
  top: 0;
  right: 0;
}
.wide-screen {
  width: 100%;
}
/*
img:before {
  content: ' ';
  display: block;
  position: absolute;
  background-image: url("fallback-image.jpg");
  background-size: contain;
  height: inherit;
  width: inherit;
  border: inherit;
  border-radius: inherit;
  outline: inherit;
  z-index: -1;
}
img:before + img {
  border:  none;
}
*/
input:disabled {
  cursor: not-allowed;
}
input:disabled ~ label {
  color: gray;
}
input:disabled + label {
  color: gray;
}

div,
span,
p,
button,
input,
textarea {
  font: inherit;
}

.early-access {
  font-size: var(--font-size-xxs);
  font-style: italic;
  box-shadow: 0px 3px 8px -4px red;
  margin-top: -2px;
}
.features-img-container {
  margin: 10px;
  /*display: flex;*/
  justify-content: center;
  position: relative;
}
.features-img {
  width: 350px;
}
.thumbnail-container {
  padding: 4px;
  border: 1px solid gray;
}
.thumbnail-container input {
  max-width: 22ch;
}
.thumbnail-heading {
  display: flex;
  justify-content: space-between;
}
.thumbnail-container .file {
  margin: 4px 0 0 0;
  width: 100%;
}
.file-remove {
  text-align: right;
}
.file-remove svg {
  width: 18px;
  height: 18px;
  padding: 0 1px 0 3px;
}
/*.mobile {
  width: 95%;
}*/
.pre-load-img {
  height: 350px;
}
.mobile .pre-load-img {
  height: 90vw;
}
.printable {
  width: fit-content;
  margin: 0 auto;
}
.transparent {
  color: transparent;
}

.icon {
  cursor: pointer;
}
.icon svg {
  /*width: 26px;*/
  height: 26px;
  padding: 10px;
}
.small-icon svg {
  width: 18px;
  height: 18px;
  padding: 7px;
}
.nav-item {
  padding: 10px 20px;
  display: block;
  cursor: pointer;
  border-radius: 4px;
}
.nav-item:hover {
  background-color: var(--nav-item-hover);
}

.nav-item.active {
  border-bottom: inset var(--dark-blue);
}
.refresh-icon-container svg {
  /*   padding: 10px;
margin: 15px 0 0 0; */
  vertical-align: super;
}
.refresh-icon-container svg {
  vertical-align: sub;
}
.network-status {
  font-size: 12px;
  text-align: right;
  /* align-self: center; */
}
.industries-list {
  display: flex;
}
.error-text {
  color: red;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.error-text:hover {
  white-space: normal;
  overflow: visible;
}
.section-divider {
  height: 20px;
}
.small-line-break {
  height: 10px;
}
.swipe-hide {
  left: -101vw !important;
  /*max-height: 0vh !important;*/
  padding: 0 !important;
  margin: 0 !important;
  transition: all 0.2s linear;
  position: absolute !important;
  /*width: 0 !important;*/
  max-width: 100vw !important;
}
.swipe-hide.right {
  left: 101vw !important;
}
.swipe-show {
  left: 0;
  position: relative;
  /*transition: all 0.3s cubic-bezier(0.55, 1.41, 0.82, 0.93);*/
  /*transition: all 2.3s cubic-bezier(0.21, 0.97, 0.94, 0.99);*/
  transition: all 0.2s linear;

}
.hidden {
  opacity: 0;
  z-index: -1;
  transition: opacity 3s linear;
}
.hide {
  opacity: 0;
  z-index: -1;
}
.visible {
  max-height: 50px;
  opacity: 0.8;
  z-index: unset;
}
.fixed-message .visible {
  max-height: 70px; /* about 3 lines */
}
.fixed-message .visible:hover {
  max-height: unset;
}
.absolute {
  position: absolute;
}
.absolute.left {
  position: absolute;
  left: 0;
}
.hide-height {
  max-height: 0px !important;
  overflow: hidden !important;
  opacity: 0;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}
.show-height {
  /*max height 200px otherwise height doesnt transition*/
  max-height: 200px;
  opacity: 1;
  transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
}
.hide-block {
  display: none;
}
.show-block {
  display: unset;
}
.display-inline {
  display: inline;
}
.display-inline-block {
  display: inline-block;
}
.display-block {
  display: block;
}
.live-entry {
  background-color: lightgreen;
  transition: background-color 0.5s linear;
}
.live-entry-fade {
  background-color: white;
  transition: background-color 0.5s linear;
}
.large {
  font-size: 30px;
}
.medium {
  font-size: 20px;
}
.small {
  font-size: 16px;
}
.tiny {
  font-size: 12px;
}
.whisper {
  font-size: 12px;
  font-style: italic;
}
.post-time {
  font-size: var(--font-size-xs);
  color: var(--light-gray);
}
.post-time .material-icons {
  font-size: var(--font-size-m);
  display: inline-block;
  vertical-align: middle;
}
.white {
  color: var(--white-text);
}
.black {
  color: black;
}
.blue {
  color: var(--brand-blue);
}
.lightgray {
  color:  var(--light-gray);
}
.link-appearance {
  text-decoration: underline;
  cursor: pointer !important;
}
.no-link {
  text-decoration: none;
  color: inherit;
}
.right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.no-list-decoration {
  list-style: none;
  padding: 0;
}
/*.invoices-list:hover details summary {
  opacity: 0.8
}
.invoices-list details[open] summary {
  position: top
}*/

button.locked {
  padding-right: 15px;
  cursor: not-allowed;
}
button.locked:after {
  content:  "🔒";
  margin-left: 1.5ch;
/*  background-color: white;
  border-radius: 50%;
  padding: 5px;*/
  /*margin-left:  2ch;*/
  /*margin-right:  -1ch;*/
  /*position: absolute;*/
  /*right: 0;*/
  /*vertical-align: top;*/
}

.red {
  background-color: red;
}
.green {
  background-color: #27c727;
}
.button-appearance {
  /*box-shadow: 0px 0px 4px 1px #ada9a9;*/
  cursor: pointer;
  background-color: var(--brand);
  color: var(--white-text);
  font-family: var(--font-family-gilroy-bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  letter-spacing: 0;
  min-height: 20px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 10px;
  border-style: none;
  align-items: center;
}
.button-appearance.tiny {
  font-family: var(--font-family-gilroy-semibold);
  font-size: var(--font-size-xs);
  font-weight: 700;
  letter-spacing: 0;
  min-height: 20px;
  display: inline-block;
  padding: 6px 18px 3px 18px;
  border-radius: 8px;
  border-style: none;
  text-align: center;
}
.button-appearance.tiny .material-icons {
  font-size: 13px;
}
.button-appearance.tiny.no-icon-or-underline {
  padding: 4px 18px 4px 18px;
}
.button-appearance:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.button-appearance:hover {
  background-color: #1a50a9;
}
.button-appearance.gray {
  background-color: #b9b9b9;
}
.button-appearance.gray:hover {
  background-color: gray;
}
.button-appearance.red {
  background-color: red;
}
.button-appearance.red:hover {
  background-color: #b30000;
}
.button-appearance.green {
  background-color: #27c727;
}
.button-appearance.green:hover {
  background-color: #1c931c;
}
.quick-action-buttons {
  /*position: fixed;*/
  /*margin: 0 0 40px 0;*/
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.start-button {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  padding: 5px 0 20px 0;
  background-color: transparent;
  cursor: pointer;
}
.start-button div {
  min-width: 200px;
  max-width: 500px;
  padding: 10px 20px;
  font-size: 20px;
  background-color: green;
  border-radius: 7px;
  /*border: outset 2px #ada9a9;*/
  box-shadow: 0px 0px 4px 1px #ada9a9;
}
.small-button {
  padding: 1px 2px;
  font-size: 12px;
}
.block-element {
  display: block;
}
.flex-list {
  display: flex;
  justify-content: space-between;
}
/* .justify-flex-end {
  justify-content: flex-end
} */
.stealth-input {
  font-family: inherit;
  background: transparent;
  border: none;
}
/* .controls-links {
  padding: 10px 0 5px 0;
  min-height: 16px;
  font-size: 14px;
  text-align: center;
} */
/*#cancel-link a {
  font-size: 14px;
  color: white;
}*/

@media print {
  @page {
    size: 8.5in 11in;
    margin: 0.5in;
  }
  body {
    background-color: white !important;
  }
  .App {
    margin: 0 auto;
  }
  .noprint {
    display: none !important;
  }
  .printable {
    /* position: absolute !important; */
    background-color: transparent;
  }
  .printable tr {
    page-break-inside: avoid !important;
    -webkit-column-break-inside: avoid !important;
  }
  .printable div:last-child {
    height: auto;
  }
  .page-spacer {
    width: 100%;
  }
  div.invoice-page {
    vertical-align: top !important;
    /*page-break-after: always !important;*/
    left: 0 !important;
    top: 0 !important;
    /*must have negative margins to aklign page center*/
    margin-top: -0.25in;
    margin-right: -0.25in;
    margin-bottom: -0.25in;
    margin-left: -0.25in;
    box-shadow: none !important;

    /*    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0in;*/
  }
  table {
    page-break-inside: avoid !important;
    -webkit-column-break-inside: avoid !important;
  }
  table td {
    page-break-inside: avoid !important;
    -webkit-column-break-inside: avoid !important;
  }
}
